import { ThemeConfig } from "@/configs/theme.config";
import { usePermissionStore } from "@/stores/permission.store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ConfigProvider from "antd/es/config-provider";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { Router } from "./router";

import locale from 'antd/locale/en_US';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

export const Application = () => {
  const { mapPermission } = usePermissionStore((state) => state);

  useEffect(() => {
    mapPermission();
  }, [mapPermission]);

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider theme={ThemeConfig} locale={locale}>
        <RouterProvider router={Router} />
      </ConfigProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
