import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { FC } from "react";

export const SpinnerFull: FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={`h-full w-full flex ${className}`}>
      <Spin className="m-auto" indicator={<LoadingOutlined />} />
    </div>
  );
};

export const SpinnerContainer: FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={`h-screen w-full flex ${className}`}>
      <Spin className="m-auto" indicator={<LoadingOutlined />} />
    </div>
  );
};
