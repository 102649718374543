import { SuspenseWrapperProps } from "@/utils/types/props.type";
import { Suspense } from "react";
import { SpinnerContainer, SpinnerFull } from "./spinner";

const SuspenseWraper: SuspenseWrapperProps = ({ LazyComponent, type }) => {
  return (
    <Suspense
      fallback={type === "full" ? <SpinnerFull /> : <SpinnerContainer />}
    >
      <LazyComponent />
    </Suspense>
  );
};

export default SuspenseWraper;
