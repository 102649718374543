import { ThemeConfig as ThemeConfigAntd } from "antd";

export const ThemeConfig: ThemeConfigAntd = {
  token: {
    colorPrimary: "#25C196",
    colorBgBase: "#FFFFFF",
    colorBgLayout: "#f0f2f5",
    colorText: "#282E2C",
  },
};
