import { PublicRoute } from "@/components/router";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

const BasePage = () => {
  const { token: tokenResetPassword } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get("token");
    const isLoggedIn = token ? true : false;
    if (
      (PublicRoute.includes(
        location.pathname.replace("/" + tokenResetPassword, ""),
      ) ||
        location.pathname === "/") &&
      isLoggedIn
    ) {
      const dashboard = Cookies.get("redirect-dashboard") ?? "/dashboard";
      return navigate(dashboard, { replace: true });
    }

    if (
      (!PublicRoute.includes(
        location.pathname.replace("/" + tokenResetPassword, ""),
      ) ||
        location.pathname === "/") &&
      !isLoggedIn
    ) {
      return navigate("/auth/login", { replace: true });
    }
  }, [navigate, location, tokenResetPassword]);

  return <Outlet />;
};

export default BasePage;
