import Cookies from "js-cookie";
import { create } from "zustand";

interface PermissionStore {
  permissions: string[];
  mapPermission(): void;
}

export const usePermissionStore = create<PermissionStore>((set) => ({
  permissions: [],
  mapPermission: () => {
    const permissions = JSON.parse(Cookies.get("permissions") ?? "[]");
    set({ permissions });
  },
}));
