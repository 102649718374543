import {
  AuthLoginPage,
  AuthPasswordRecoveryPage,
  AuthResetPasswordPage,
} from "@/pages/auth";
import BasePage from "@/pages/base_page";
import {
  BillingDetailPage,
  BillingHistoryListPage,
  BillingListPage,
} from "@/pages/billing";
import { PackageAccessControl, PackageListPage } from "@/pages/billing/package";
import { VoucherListPage } from "@/pages/billing/voucher";
import { DashboardAdminPage, DashboardPartnerPage } from "@/pages/dashboard";
import NotFoundPage from "@/pages/errors/not_found_page";
import { PartnerListPage } from "@/pages/management/partner";
import { UserAccessControlCustomerPage, UserAccessControlPage, UserCustomerListPage, UserListPage } from "@/pages/management/user";
import { Suspense, lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import { SpinnerFull } from "./generals/spinner";
import SuspenseWraper from "./generals/suspense_wraper";

const NotFoundContainerPage = lazy(
  () => import("@/pages/errors/not_found_container_page"),
);
const SidebarLayout = lazy(() => import("./layouts/sidebar_layout"));

export const Router = createBrowserRouter([
  {
    path: "/",
    element: <BasePage />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: "auth",
        children: [
          {
            path: "login",
            element: (
              <SuspenseWraper type="full" LazyComponent={AuthLoginPage} />
            ),
          },
          {
            path: "password-recovery",
            element: (
              <SuspenseWraper
                type="full"
                LazyComponent={AuthPasswordRecoveryPage}
              />
            ),
          },
          {
            path: "reset-password/:token",
            element: (
              <SuspenseWraper
                type="full"
                LazyComponent={AuthResetPasswordPage}
              />
            ),
          },
        ],
      },
      {
        path: "dashboard",
        element: (
          <Suspense fallback={<SpinnerFull />}>
            <SidebarLayout />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: (
              <SuspenseWraper
                type="container"
                LazyComponent={NotFoundContainerPage}
              />
            ),
          },
          {
            path: "admin",
            element: (
              <SuspenseWraper
                type="container"
                LazyComponent={DashboardAdminPage}
              />
            ),
          },
          {
            path: "partner",
            element: (
              <SuspenseWraper
                type="container"
                LazyComponent={DashboardPartnerPage}
              />
            ),
          },
        ],
      },
      {
        path: "management",
        children: [
          {
            path: "user",
            element: (
              <Suspense fallback={<SpinnerFull />}>
                <SidebarLayout />
              </Suspense>
            ),
            children: [
              {
                index: true,
                element: (
                  <SuspenseWraper
                    type="container"
                    LazyComponent={NotFoundContainerPage}
                  />
                ),
              },
              {
                path: "list",
                element: (
                  <SuspenseWraper
                    type="container"
                    LazyComponent={UserListPage}
                  />
                ),
              },
              {
                path: "list-customer",
                element: (
                  <SuspenseWraper
                    type="container"
                    LazyComponent={UserCustomerListPage}
                  />
                ),
              },
              {
                path: "access-control",
                element: (
                  <SuspenseWraper
                    type="container"
                    LazyComponent={UserAccessControlPage}
                  />
                ),
              },
              {
                path: "access-control-customer",
                element: (
                  <SuspenseWraper
                    type="container"
                    LazyComponent={UserAccessControlCustomerPage}
                  />
                ),
              },
            ],
          },
          {
            path: "partner",
            element: (
              <Suspense fallback={<SpinnerFull />}>
                <SidebarLayout />
              </Suspense>
            ),
            children: [
              {
                index: true,
                element: (
                  <SuspenseWraper
                    type="container"
                    LazyComponent={NotFoundContainerPage}
                  />
                ),
              },
              {
                path: "list",
                element: (
                  <SuspenseWraper
                    type="container"
                    LazyComponent={PartnerListPage}
                  />
                ),
              },
            ],
          },
        ],
      },
      {
        path: "billing",
        element: (
          <Suspense fallback={<SpinnerFull />}>
            <SidebarLayout />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: (
              <SuspenseWraper
                type="container"
                LazyComponent={NotFoundContainerPage}
              />
            ),
          },
          {
            path: "list",
            element: (
              <SuspenseWraper
                type="container"
                LazyComponent={BillingListPage}
              />
            ),
          },
          {
            path: "detail",
            element: (
              <SuspenseWraper
                type="container"
                LazyComponent={BillingDetailPage}
              />
            ),
          },
          {
            path: "history/list",
            element: (
              <SuspenseWraper
                type="container"
                LazyComponent={BillingHistoryListPage}
              />
            ),
          },
          {
            path: "voucher/list",
            element: (
              <SuspenseWraper
                type="container"
                LazyComponent={VoucherListPage}
              />
            ),
          },
          {
            path: "package/list",
            element: (
              <SuspenseWraper
                type="container"
                LazyComponent={PackageListPage}
              />
            ),
          },
          {
            path: "package/access-control",
            element: (
              <SuspenseWraper
                type="container"
                LazyComponent={PackageAccessControl}
              />
            ),
          },
        ],
      },
    ],
  },
]);

export const PublicRoute = [
  "/auth/login",
  "/auth/register",
  "/auth/password-recovery",
  "/auth/reset-password",
];

export const LinkPage = {
  // Base
  BASE: "/",

  // Auth
  AUTH_LOGIN: "/auth/login",
  AUTH_PASSWORD_RECOVERY: "/auth/password-recovery",
  AUTH_RESET_PASSWORD: "/auth/reset-password",

  // Overview
  DASHBOARD_ADMIN: "/dashboard/admin",
  DASHBOARD_PARTNER: "/dashboard/partner",

  // Management
  USER_LIST: "/management/user/list",
  USER_LIST_CUSTOMER: "/management/user/list-customer",
  USER_ACCESS_CONTROL: "/management/user/access-control",
  USER_ACCESS_CONTROL_CUSTOMER: "/management/user/access-control-customer",
  PARTNER_LIST: "/management/partner/list",

  // Billing
  BILLING_LIST: "/billing/list",
  BILLING_DETAIL: "/billing/detail",
  BILLING_HISTORY_LIST: "/billing/history/list",
  BILLING_HISTORY_DETAIL: "/billing/history/detail",
  VOUCHER_LIST: "/billing/voucher/list",
  PACKAGE_LIST: "/billing/package/list",
  PACKAGE_ACCESS_CONTROL: "/billing/package/access-control",

  // Dashboard
  HELPDESK_QNA: "/helpdesk/qna",
  HELPDESK_CONTACT_SUPPORT: "/helpdesk/contact-support",
};

export const redirectDashboard = (permissions: string[]) => {
  const permissionDashboard =
    permissions.find((item) => item.includes("DASHBOARD_")) ?? "";
  return LinkPage[permissionDashboard as keyof typeof LinkPage];
};
